import { memo } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { isMobile } from 'react-device-detect';
import WistiaPlayer from '../../Shared/WistiaPlayer';

import Styles from './styles.module.scss';

export const VideoModal = ({ show, closeModal, source }) => (
  <Modal
    show={show}
    onHide={closeModal}
    dialogClassName={Styles['video-modal']}
    centered
  >
    <WistiaPlayer
      url={source}
      style={{
        margin: 'auto',
        width: '100%',
        height: '99%',
      }}
      controls
      config={{
        options: isMobile ? { playsinline: false } : {},
      }}
    />
  </Modal>
);

VideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
};

export default memo(VideoModal);
